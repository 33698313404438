import React, { useEffect, useState } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import CryptoJS from 'crypto-js';

// Fungsi untuk mendekripsi nilai yang sudah dienkripsi
const decryptValue = (encryptedValue) => {
  const encryptionKey = CryptoJS.enc.Utf8.parse('VISION1000567896'); 
  const iv = CryptoJS.enc.Hex.parse('00000000000000000000000000000000'); 

  try {
    const decodedWordArray = CryptoJS.enc.Base64.parse(encryptedValue);

    const decrypted = CryptoJS.AES.decrypt({ ciphertext: decodedWordArray }, encryptionKey, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error('Error decrypting value:', error.message);
    return null;
  }
};

// Fungsi untuk mendekode Base64
const decodeBase64 = (encodedString) => {
  try {
    return atob(encodedString);
  } catch (error) {
    console.error('Error decoding Base64:', error.message);
    return null;
  }
};

const QRCodeScanner = () => {
  const [scanner, setScanner] = useState(null);
  const [scanResult, setScanResult] = useState(null);
  const [decodedResult, setDecodedResult] = useState(null);

  useEffect(() => {
    const checkCameraPermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({ name: 'camera' });
        if (permissionStatus.state === 'denied') {
          alert('Izin untuk mengakses kamera telah ditolak. Harap aktifkan izin kamera di pengaturan browser Anda.');
        }
      } catch (error) {
        console.error('Error memeriksa izin kamera:', error.message);
        alert('Kami tidak dapat memeriksa izin kamera. Periksa pengaturan privasi browser Anda.');
      }
    };

    const startScanner = () => {
      const html5QrcodeScanner = new Html5QrcodeScanner(
        'qr-reader',
        { fps: 5, qrbox: { width: 250, height: 250 } },
        false
      );

      html5QrcodeScanner.render(
        (decodedText, decodedResult) => {
          // Menangani kedua jenis hasil pemindaian QR
          const [baseUrl, encodedPart] = decodedText.split('/api/core/accept-pengiriman-barang');
          let decodedTextDecoded = decodedText;

          if (encodedPart) {
            decodedTextDecoded = `${baseUrl}/api/core/accept-pengiriman-barang${decodeBase64(encodedPart)}`;
          } else {
            decodedTextDecoded = decryptValue(decodedText);
          }

          setScanResult(decodedText);
          setDecodedResult(decodedTextDecoded);
          html5QrcodeScanner.clear();
        },
        (error) => {
          console.error('QR Code scan error:', error.message);
        }
      );

      setScanner(html5QrcodeScanner);
    };

    checkCameraPermission().then(() => {
      if (scanner === null) {
        startScanner();
      }
    });

    return () => {
      if (scanner) {
        scanner.clear();
      }
    };
  }, [scanner]);

  return (
    <div>
      <div id="qr-reader" style={{ width: '100%', height: '100%' }}></div>
      {decodedResult && (
        <div className="popup">
          <div className="popup-content">
            <p>Hasil Pindai: {decodedResult.startsWith('http') ? <a href={decodedResult} target="_blank" rel="noopener noreferrer">{decodedResult}</a> : decodedResult}</p>
          </div>
        </div>
      )}
      <style jsx>{`
        .popup {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 20px;
          background-color: white;
          border: 1px solid #ccc;
          border-radius: 5px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          z-index: 1000;
        }
        .popup-content {
          text-align: center;
        }
        .popup a {
          color: #007bff;
          text-decoration: none;
        }
        .popup a:hover {
          text-decoration: underline;
        }
      `}</style>
    </div>
  );
};

export default QRCodeScanner;
