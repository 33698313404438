import moment from "moment";
import "moment/locale/id"; //
import { Skeleton } from "antd";

export const separateDash = (data, col, isRp = true) => {
  let str = "";
  data.map((res, i) => {
    str +=
      data.length - 1 !== i
        ? `${isRp ? toRp(res[col]) : res[col]} | `
        : isRp
        ? toRp(res[col])
        : res[col];
  });
  return str;
};

export const rmCommaToPoint = (res = "") => {
  return res.replaceAll(",", ".");
};

export const PAGINATION = {
  offset: 1,
  limit: 25,
};

export const DURRABILLTY = [
  { value: 1, label: "Harian ( 1 Hari )" },
  { value: 7, label: "Mingguan ( 7 Hari )" },
  { value: 30, label: "Bulanan ( 30 Hari )" },
];

export const toRp = (num, isPoint = false) => {
  let initNum = 0;
  if (parseFloat(num) === 0) return 0;
  initNum = parseFloat(num) < 0 ? num.toString().replace("-", "") : num;
  var numStr = !handleEmpty(initNum)
    ? String(0.0)
    : initNum.toString().replace(/,|\D/g, "");
  let split = numStr.split(".");
  let sisa = split[0].length % 3;
  let rp = split[0].substr(0, sisa);
  let thousand = split[0].substr(sisa).match(/\d{3}/gi);

  if (thousand) {
    var separator = sisa ? "." : "";
    rp += separator + thousand.join(".");
  }
  rp = handleEmpty(split[1]) ? `${rp},${split[1]}` : rp;
  rp.replace(/^0+/, "");

  return num === "" ? "" : rp;
};

export const rmComma = (val) => {
  return val.replaceAll(".", "");
};
export const rmU = (val) => {
  return val.replaceAll("_", " ");
};

export const handleEmpty = (val) => {
  if (val === undefined || val === null || val === "") {
    return false;
  }
  return true;
};

export const generateNo = (i, current_page, perpage = 10) => {
  return i + 1 + perpage * (parseInt(current_page, 10) - 1);
};

export const separatorComma = (arr, col) => {
  let strLoc = "";
  let sparator = "";
  if (typeof arr === "object") {
    arr.map((res, key) => {
      sparator = arr.length - 1 === key ? "" : ", ";
      strLoc += `${res[col]}${sparator}`;
    });
    return strLoc;
  } else {
    return arr;
  }
};

export const getOffset = (offset, limit = PAGINATION.limit) => {
  return offset * limit - limit;
};

export const rulesPhone = () => {
  return [
    { required: true, message: ucWords("tidak boleh kosong") },
    {
      pattern: new RegExp(/^[0-9]*$/),
      message: ucWords("tidak boleh memasukan selain angka"),
    },
    { min: 9, message: ucWords("no handphone tidak valid") },
    { max: 15, message: ucWords("no handphone tidak valid") },
  ];
};

export const rulesNumber = () => {
  return [
    { required: true, message: ucWords("tidak boleh kosong") },
    {
      pattern: new RegExp(/^[0-9]*$/),
      message: ucWords("tidak boleh memasukan selain angka"),
    },
  ];
};

export const rulesGeneral = () => {
  return [{ required: true, message: ucWords("tidak boleh kosong") }];
};

export const rulesEmail = () => {
  return [
    { required: true, message: ucWords("tidak boleh kosong") },
    {
      type: "email",
      message: ucWords("email tidak valid"),
    },
  ];
};

export const dateIndo = (val, format = "ll") => {
  return moment(val).format(format);
};

export const tagStatus = (val) => {
  let color = "";
  if (
    val === "ACCEPTED" ||
    val == "APPLIED" ||
    val == "APPROVED" ||
    val == "TRANSFERRED" ||
    val == "COMPLETED" ||
    val == "DELIVERED"
  ) {
    color = "cyan";
  } else if (
    val === "WAITING_USER" ||
    val == "WAITING_ADMIN" ||
    val == "RELEASED"
  ) {
    color = "geekblue";
  } else {
    color = "volcano";
  }
  return color;
};

export const tagStatusSettlement = (val) => {
  let color = "";
  if (val === "ACCEPTED") {
    color = "cyan";
  } else if (val === "APPLIED") {
    color = "blue";
  } else {
    color = "volcano";
  }
  return color;
};

export const checkDeleteData = (data, offset, callback) => {
  if (data.length === 1) {
    let newOffset = getOffset(offset);
    if (newOffset >= PAGINATION.limit) {
      callback(offset - PAGINATION.offset);
    } else {
      callback(null);
    }
  } else {
    callback(null);
  }
};
export const clearNotif = () => {
  setTimeout(() => {
    document.getElementById("msg").style.display = "none";
  }, 7000);
};

export const iconSuccess = `<svg
    viewBox="64 64 896 896"
    focusable="false"
    data-icon="check-circle"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path>
  </svg>`;

export const iconError = `<svg
    viewBox="64 64 896 896"
    focusable="false"
    data-icon="close-circle" 
    width="1em" 
    height="1em"
    fill="currentColor" 
    aria-hidden="true"
  >
    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
</svg>`;

export const showMessage = (msg = "", type = "error") => {
  var elem = document.getElementById("msg");
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  let arial = "close-circle";
  let icon = iconError;
  let bg = "#ff6b72";
  if (type === "success") {
    arial = "check-circle";
    icon = iconSuccess;
    bg = "rgb(38, 135, 86)";
  }
  var s = elem.style;

  if (s !== undefined) {
    s.display = "block";
    s.opacity = 1;
    return (elem.innerHTML = `<div style="border:none;opacity: 1;margin-bottom: 20px;"><div style="border:1px solid ${bg};background-color:${bg}" data-show="true" class="ant-alert ant-alert-${type} role="alert"><span style="color:white" role="img" class="anticon anticon-${arial} ant-alert-icon" aria-label="${arial}">${icon}</span><div class="ant-alert-content"><div class="ant-alert-message " style="font-weight:bold;margin-top:1px;color:white">${msg}</div></div></div></div>`);
  }
  clearNotif();
};

export const checkLoading = async (url) => {
  // const forageStore = localforage.createInstance({
  //   name: dbName,
  // });
  // const getGekys = await forageStore.keys();
  // const checkUrl = getGekys.filter((row) => row === env.API + "/" + url);
  // if (checkUrl.length > 0) {
  //   return false;
  // } else {
  //   return true;
  // }
  return true;
};

export const forLoop = (i = 3) => {
  let container = [];
  (() => {
    for (let i = 0; i < i; i++) {
      container.push(<Skeleton paragraph={true} active={true} />);
    }
  })();
  return container;
};

export const sumStock = (arr, isTrx = false) => {
  let stock = 0;
  // console.log(arr)

  arr.map((res, i) => {
    console.log(res.item_id,res.locked<0)
    if (isTrx) {
      // if(res.locked<0){
      //   stock+=Number(res.remaining)+Number(res.total)
      // }else{
        stock += Number(res.remaining) - Math.abs(Number(res.locked));
      // }
    } else {
      stock += Number(res.total) - (Number(res.locked) + Number(res.sold));
    }
  });
  return stock;
};

export const getFilterVendor = (arr) => {
  let newArr = [{ id: "", name: "Semua" }];
  if (arr.length < 1) {
    return newArr;
  } else {
    arr.map((res, i) => {
      newArr.push({ id: res.vendor.id, name: res.vendor.name });
    });
    return newArr;
  }
};

export const setObjectStorage = (name, val) => {
  return localStorage.setItem(name, JSON.stringify(val));
};
export const getObjectStorage = (name) => {
  const resialized = localStorage.getItem(name);
  return JSON.parse(resialized);
};
export const setStorage = (name, val) => {
  return localStorage.setItem(name, val);
};
export const getStorage = (name) => {
  return localStorage.getItem(name);
};

export const ucWords = (str) => {
  return (str + "").replace(/^([a-z])|\s+([a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
};

export const ucWords2 = (str) => {
  return str
    .toLowerCase()
    .replace(/\b\w/g, firstChar => firstChar.toUpperCase());
}

export const capitalizeEachWord = (str) => {
  let splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

export const tooltipPass = () => {
  return (
    <>
      minimal 8 karakter <br />
      maksimal 30 karakter <br />
      minimal 1 huruf besar <br />
      minimal 1 huruf kecil <br />
      minimal 1 angka <br />
      minimal 1 spesial karakter (#$@!%&*?) <br />
    </>
  );
};

export const patterAndMsgPass = () => {
  return {
    pattern: new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/
    ),
    message:
      "Format Salah, Klik Atau Dekatkan Kursor Ke Ikon Tanda Tanya Untuk Melihat Format",
  };
};

export const rulePass = {
  tooltip: tooltipPass(),
  pattern: patterAndMsgPass(),
};

export const matchStr = (val1, val2) => {
  return val1.toLowerCase().includes(val2.toLowerCase());
};

export const terbilang = (number) => {
  const units = ['', 'Satu', 'Dua', 'Tiga', 'Empat', 'Lima', 'Enam', 'Tujuh', 'Delapan', 'Sembilan'];
  const teens = ['Sepuluh', 'Sebelas', 'Dua Belas', 'Tiga Belas', 'Empat Belas', 'Lima Belas', 'Enam Belas', 'Tujuh Belas', 'Delapan Belas', 'Sembilan Belas'];
  const tens = ['', '', 'Dua Puluh', 'Tiga Puluh', 'Empat Puluh', 'Lima Puluh', 'Enam Puluh', 'Tujuh Puluh', 'Delapan Puluh', 'Sembilan Puluh'];
  const scales = ['', 'Ribu', 'Juta', 'Miliar', 'Triliun'];

  if (number === 0) {
    return 'Nol';
  }

  const words = [];
  let scaleIndex = 0;

  while (number > 0) {
    const lastThreeDigits = number % 1000;
    number = Math.floor(number / 1000);

    const lastTwoDigits = lastThreeDigits % 100;
    const tensDigit = Math.floor(lastTwoDigits / 10);
    const unitsDigit = lastTwoDigits % 10;

    let word = '';

    if (lastThreeDigits >= 100) {
      word += units[Math.floor(lastThreeDigits / 100)] + ' Ratus';
    }

    if (tensDigit > 1) {
      word += ' ' + tens[tensDigit];
      if (unitsDigit > 0) {
        word += ' ' + units[unitsDigit];
      }
    } else if (tensDigit === 1) {
      if (unitsDigit === 1) {
        word += ' Sebelas';
      } else {
        word += ' ' + teens[unitsDigit];
      }
    } else if (unitsDigit > 0) {
      word += ' ' + units[unitsDigit];
    }

    if (lastThreeDigits > 0) {
      word += ' ' + scales[scaleIndex];
    }

    words.unshift(word);
    scaleIndex++;
  }

  return words.join(' ');
};