import React, { createContext, memo, useContext, useEffect, useState } from 'react'
import LandingPage from '../pages/landingpage'
import axios from 'axios'
const controller = createContext(null)
export const useControllerState = () => {
    return useContext(controller)
}
const Controller = memo(() => {
   
   

  return  (
   <controller.Provider
   value={
    {
   
    }
   }
   >
    <LandingPage/>
   </controller.Provider>
  )
})

export default Controller