import React from 'react'
import RequestForm from '../components/RequestForm'

const index = () => {
  return (
    <div>
      <RequestForm/>
    </div>
  )
}

export default index
