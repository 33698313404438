import React, { useState } from 'react'
import QRCodeScanner from './QRCodeScanner';
const QRCode = () => {
 
  return (
    <>
    <div>
    
      <div style={{textAlign:'center'}}>
        <QRCodeScanner/>
      </div>
      
    </div>
    </>
  )
}

export default QRCode
